import React, {useState} from "react";
import "./header.css";
import PDF from "./Summer 2023-Enginering.pdf"
import {ReactComponent as Icon} from './NavIcon.svg';

function Navbar(props){
    return(
        <div className="navbar">
            <ul className="navbar-nav">{props.children}</ul>
        </div>
    )
}

function NavItem(props){
    const[open, setOpen] = useState(false);
    return(
        <li className="nav-item">
            <a className="text-button" onClick={() => setOpen(!open)}>{props.icon}</a>
            
            {open && props.children}
        </li>
    )
}

function header(){
    return(
        <div className="sticky">
            <Navbar>
                <NavItem icon={<Icon/>}>
                    <div className="posdiv">
                        <li><a href="/">Home</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href={PDF} target="_blank">Resume</a></li>
                        <li><a href="#contacts">Contacts</a></li>
                    </div>
                </NavItem>

            </Navbar>
        </div>
    )
}

export default header;
