import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "./components/body/about";
import Home from "./components/body/highlights";
import Work from "./components/body/work";
import Projects from "./components/body/projects";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>  
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="*">
              <Home />
            </Route>
          </Switch>
        </Router>
        <Header></Header>
      </div>
    );
  }
}

export default App;
