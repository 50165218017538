import React from "react";
import "./home.css";
import City from "../../Images/CityOfOttawa.jpeg";
import Digitera from "../../Images/Digitera.png";
import Vex from "./photos/Vex.jpg";
import TBWC from "./photos/TBWC.jpg";
import cu from "../../Images/CU.jpg";
import Footer from "../footer/footer";
import APPS from "./photos/APPS.png";
import Mancala from "./photos/Mancala.png";
// aos is a animation libary
import ParticleBackground from "../../ParticleBackground";
import AOS from "aos";
import "aos/dist/aos.css"; // also use css animations for styles
import { getByDisplayValue } from "@testing-library/react";

// ..
//time duration of animations

//information

AOS.init({
  duration: 500,
});

function Name() {
  return (
    <div className="Intro">
      <ParticleBackground />
      {/* animation done in css */}
      <div className="IntroDiv">
        <div
          data-aos="fade-right"
          data-aos-anchor-placement="center-left"
          className="IntroDivChild"
          data-aos-delay="600"
          data-aos-duration="1300"
        >
          <h1>Hi, it's me Chris Walker</h1>
          <p>
            A multidisciplinary engineering student whose currently working on
            improving his skills
          </p>
        </div>
        {/* <div
          data-aos="fade-left"
          data-aos-anchor-placement="center-bottom"
          data-aos-delay="600"
          data-aos-duration="1300"
          className="IntroDivChild"
          style={{  width:"30vw"}}
        >
          <img className="TitleImage"></img>
        </div> */}
      </div>
    </div>
  );
}

function About() {
  return (
    <div className="about">
      <div className="aboutWrapper">
        <p data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
          About Me
        </p>
        <h1 data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
          Here's a Bit of{" "}
        </h1>
        <h1 data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
          Information About Me.
        </h1>
        <div className="aboutNumberDiv">
          <div
            className="aboutNumberDivChild"
            data-aos="fade-right"
            data-aos-delay="700"
            data-aos-duration="1000"
          >
            <h1>01</h1>
            <h2 style={{ color: "#3cc74e" }}>What&nbsp;</h2>
            <h2>I Am Doing</h2>
            <p>
              I'm currently studying Engineering Systems and Computing at the
              University of Guelph, where I learn a mix of computer engineering,
              computer science, and mechanical engineering. On top of studying,
              I am an active member of my school's Formula Student design team,
              where I do electrical work.
            </p>
          </div>
          <div
            className="aboutNumberDivChild"
            data-aos="fade-right"
            data-aos-delay="1700"
            data-aos-duration="1000"
          >
            <h1>02</h1>
            <h2 style={{ color: "#609bff" }}>What&nbsp;</h2>
            <h2>My Hobbies Are</h2>
            <p>
              Outside of spending time studying and working with my design team,
              I enjoy spending my time playing volleyball, spending time with
              friends, watching TV, and eating good food.
            </p>
          </div>
          <div
            className="aboutNumberDivChild"
            data-aos="fade-right"
            data-aos-delay="2700"
            data-aos-duration="1000"
          >
            <h1>03</h1>
            <h2 style={{ color: "#e95d90" }}>What&nbsp;</h2>
            <h2>My Goals Are</h2>
            <p>
              My goal is to develop my engineering knowledge, technical skills,
              and communications skills to a level where I can contribute to any
              engineering project with a high level of competence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Work() {
  return (
    <div className="career">
      <div
        className="careerTitle"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <p className="">My Journey</p>
        <h1>
          From learning how to code in high school to Designing PCBs, here is my
          journey to becoming a well-rounded engineer
        </h1>
      </div>

      <div className="timeline">
        <div className="timeline__middle">
          <div className="timeline_point"></div>
        </div>
        <div
          className="timline__components"
          data-aos="slide-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="timeline__date">Feburary 2019 - June 2022</div>
        </div>

        <div
          className="timline__components timeline__component--bg"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h1>Where I started</h1>
          <p>
            My first real engineering experience was at the beginning of high
            school when I learned coding through the Kinaxis Hackathon. We
            developed a video game based around mental health and finished 2nd
            in the competition. From there, I continued to participate and
            volunteer in hackathons. The coding experience gave me a skill set
            to work on other projects like the Vex robotics competition. As I
            progressed through high school, I learned more about electronics and
            a robotics project upon graduation.
          </p>
        </div>
        <div className="timeline__middle">
          <div className="timeline_point"></div>
        </div>
        <div
          className="timline__components"
          data-aos="slide-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="timeline__date">July 2021 - August 2021</div>
        </div>

        <div
          className="timline__components timeline__component--bg"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h1>Full Stack PHP Developer</h1>
          <h2>Co-op at Digitera</h2>{" "}
          <p>
            With the coding experience I gained, I was offered a co-op placement
            at Digitera as a full stack developer. During high school co-op, I
            worked on a school voting website in its alpha phase. One task was
            to inspect all aspects of the program, find the bugs and correct it.
            I was able to recognize a major bug that allowed the user to vote
            multiple times for the same election. I corrected this by creating a
            new column in each elections database table that would store the
            user id number. It would deny the user access to vote again if their
            id already existed inside the table. In addition, I could then
            implement a data tracking system for various users so that the
            company and schools could see how many elections and votes occurred.
            This also gave an opportunity for the company to gain access to
            information on the data of schools and school boards activities.
          </p>
        </div>
        <div className="timeline__middle">
          <div className="timeline_point"></div>
        </div>
        <div
          className="timline__components"
          data-aos="slide-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="timeline__date">September 2022 - Now</div>
        </div>

        <div
          className="timline__components timeline__component--bg"
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <h1>Electronics Team Member</h1>
          <h2>Gryphon Racing - Student Design Team</h2>
          <p>
            Being a part of Guelph's Formula 1 Student team has been an amazing
            learning opportunity. I currently work in the Low Voltage
            subsection, where I am designing 2 PCB boards: APPS and BSPD. These
            boards are used for car safety systems which sends signals to a
            separate board and will shut down the car if there is a problem
            with the sensor outputs. So far, I have learned a lot regarding
            board design including schematics, layout, component selection and tracing.In
            additon to working on PCB design, I would like to study high voltage
            and interconnect systems.{" "}
          </p>
        </div>
        <div className="timeline__middle">
          <div className="timeline_point"></div>
        </div>
        <div
          className="timline__components"
          data-aos="slide-up"
          data-aos-delay="100"
          data-aos-duration="1000"
        >
          <div className="timeline__date">To New Challenges</div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

function Project() {
  let list = [
    {
      title: "FSAE: APPS Board",
      text: "The accelerator pedal position sensor board handles data logging, ready-to-drive, power management, and other sensors. The board attaches to an stm32 Nucleo board, which performs logic on the various analog and digital signals. Depending on the sensor, it will either: store this data in an sd card or send a signal to a shutdown board.",
      image: APPS,
      link: "",
      linkText: "",
      id: 0,
      side: "right",
      type: "projectVertical",
    },

    {
      title: "Teddy Bear Wheel Chair",
      text: "This University of Guelph competition was a 2 part of a challenge where teams of 4-6 people would have to build an autonomous robot that would complete two tasks. Task 1 was to travel across hurdles as fast as possible. Task 2 was to launch a ping pong ball at an archery target.",
      image: TBWC,
      link: "Check the code out on Github",
      linkText: "https://github.com/A1igator/cuhacking2020-frontend",
      id: 1,

      side: "left",
      type: "projectVertical",
    },

    {
      title: "Vex Robotics",
      text: "One of the major robotics competitions during high school was Vex Robotics. Me and a team of 3 other students built, coded, and competed in this competition. The competition involved 2 teams on a playing field competing to try to have more blocks in their teams' zone by the end of the match.",
      image: Vex,
      link: "",
      linkText: "",
      id: 0,
      side: "right",
      type: "projectVertical",
    },

    {
      title: "Mancala",
      text: "I created a video game that follows the rules of the Mancala board game. This GUI game was created in java and lets you play either: 2 players or against an AI. When I designed the AI, I programmed it to either score alot of points or make sure its opponent could not score a high number of points.",
      image: Mancala,
      link: "Check the code out on Github",
      linkText: "https://github.com/A1igator/cuhacking2020-frontend",
      id: 1,
      side: "left",
      type: "projectVertical",
    },
  ];
  let projectList = list.map((list) => (
    <li key={list.id}>
      <div
        className="projectOutline"
        style={{ float: list.side }}
        data-aos="zoom-in"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div className={list.type}>
          <img src={list.image}></img>
          <div style={{ border: "none" }}>
            <h1>{list.title}</h1>
            <p>{list.text}</p>
            <div className="readMoreButton" style={{ width: "10vw", marginTop:"1vh" }}>
              <a href="./projects">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </li>
  ));
  return (
    <div className="projects" id="projects">
      <div className="projectswrapper">
        <div>
          <div className="projectsTitle">
            <p
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              My Projects
            </p>
            <h1
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              Some Projects I Have Recently Worked On
            </h1>
          </div>
          <ul style={{ padding: 0 }}>
            {projectList}
            <div className="middle">
              <div
                className="btn btn3"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1000"
              >
                <div>
                  <a className="" href="./projects">
                    More Projects Here
                  </a>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}

function body() {
  return (
    <div>
      <div>
        <Name></Name>
        <About></About>
        <Project></Project>
        <Work></Work>
      </div>
    </div>
  );
}

export default body;
